import { RedisKey } from "../constants";
import { IncoAPI, MarketPlaceAPI } from "../helpers/http-client";
import redisClient from "../helpers/redis-client";

class MarketPlaceService {
  getMenu = async () => {
    try {
      const keyName = RedisKey.MarketPlaceMenu;
      const redisResult = await redisClient.getValue(keyName);
      if (redisResult) return redisResult;
      const result = await MarketPlaceAPI.get(
        `MarketPlace/GetMenu?cityName=Delhi`
      );
      if (result && result.status == 200) {
        const value = result.data.status == 1 && result.data.data;
        redisClient.setValue(keyName, value);
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getCategoryData = async (catSlug: string) => {
    const keyName = RedisKey.MarketPlaceCategory;
    const redisResult = await redisClient.getValue(keyName, null, catSlug);
    if (redisResult) return redisResult;
    try {
      const result = await MarketPlaceAPI.get(
        `/MarketPlace/GetServiceCategoryProducts?searchSlug=${catSlug}&cityName=delhi&productCount=8`
      );
      if (result && result.status == 200) {
        const value = result.data.status == 1 && result.data.data;
        redisClient.setValue(keyName, value, null, catSlug);
        return value || {};
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getSubCategoryData = async (body: Object) => {
    try {
      const result = await MarketPlaceAPI.post(
        `/MarketPlace/GetCategorySpecificProductsV2`,
        body
      );
      if (result && result.status == 200) {
        const value = result.data.status == 1 && result.data.data;
        return value || {};
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getProductDetails = async (productSlug: string) => {
    const keyName = RedisKey.MarketPlaceProduct;
    const KeyPostFix = `${keyName}_${productSlug}`;
    const redisResult = await redisClient.getValue(keyName, null, KeyPostFix);
    if (redisResult) return redisResult;
    try {
      const result = await MarketPlaceAPI.get(
        `/MarketPlace/GetProductDetail?searchSlug=${productSlug}&cityName=delhi`
      );
      if (result && result.status == 200) {
        const value = result.data.status == 1 && result.data.data;
        redisClient.setValue(keyName, value, null, KeyPostFix);
        return value || {};
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getProductsBySearch = async (searchSlug: string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `/MarketPlace/GetProductSearchList?searchSlug=${searchSlug}&cityName=delhi&productCount=5`
      );
      if (result && result.status == 200) {
        const value = result.data.status == 1 && result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getHomePageData = async (body: Object) => {
    try {
      const keyName = RedisKey.MarketPlaceHomeData;
      const redisResult = await redisClient.getValue(keyName);
      if (redisResult) return redisResult;
      const result = await MarketPlaceAPI.post(
        `/MarketPlace/GetHomePageData`,
        body
      );
      if (result && result.status == 200) {
        const value = result.data.status == 1 && result.data.data;
        redisClient.setValue(keyName, value);
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getCategoryMetaDetails = async (slug: string) => {
    const keyName = RedisKey.MarketPlaceCategoryMeta;
    const redisResult = await redisClient.getValue(keyName, null, slug);
    if (redisResult) return redisResult;
    try {
      const result = await IncoAPI.get(
        `/v1/marketplace/getcategorymeta?categoryslug=${slug}`
      );
      if (result && result.status == 200) {
        const value = result.data.success == 1 && result.data.data;
        redisClient.setValue(keyName, value, null, slug);
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getSubCategoryMetaDetails = async (slug: string) => {
    const keyName = RedisKey.MarketPlaceSubCategoryMeta;
    const redisResult = await redisClient.getValue(keyName, null, slug);
    if (redisResult) return redisResult;
    try {
      const result = await IncoAPI.get(
        `/v1/marketplace/getsubcategorymeta?subcategoryslug=${slug}`
      );
      if (result && result.status == 200) {
        const value = result.data.success == 1 && result.data.data;
        redisClient.setValue(keyName, value, null, slug);
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  searchProduct = async (query: string) => {
    try {
      const result = await IncoAPI.get(
        `/v1/marketplace/searchProduct?query=${query}`
      );
      if (result && result.status == 200) {
        const value = result.data.success == 1 && result.data.data;
        return value || {};
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  checkProductAvailability = async (productId: string, pincode: string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `/MarketPlace/CheckProductAvailabilityStatus?productId=${productId}&pincode=${pincode}`
      );
      if (result && result.status == 200) {
        const value = result.data.status == 1 && result.data.data;
        return value || {};
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getCartProductDetails = async (body: {
    CityName: string;
    ListProduct: [
      {
        productId: number;
        quantity: number;
        isProductSelected: boolean;
      }
    ];
  }) => {
    try {
      const result = await MarketPlaceAPI.post(
        `/MarketPlace/GetCartProductDetails`,
        body
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value;
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  addToCart = async (
    body: [{ productId: number; quantity: number; isProductSelected: boolean }],
    token: string
  ) => {
    try {
      const result = await MarketPlaceAPI.post(
        `/MarketPlace/AddToCart`,
        { LstProducts: body },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (result && result.status == 200 && result.data.status == 1) {
        return true;
      } else return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  getCityStateByPincode = async (pincode: string, token: string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `/common/GetCityStateByPincode?pincode=${pincode}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || {};
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getCity = async (token: string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `/common/GetCityList?countryName=india`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getState = async (cityId: string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `/Common/GetStateCountryByCityId?cityId=${cityId}`
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  addAddress = async (body: any, token: string) => {
    try {
      const result = await MarketPlaceAPI.post(`/MarketPlace/AddAddress`, body, {
        headers: {
          Authorization: token,
        },
      });
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value;
      } else return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  getAddressList = async (token: string) => {
    try {
      const result = await MarketPlaceAPI.get(`/marketPlace/GetAddressList`, {
        headers: {
          Authorization: token,
        },
      });
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getAddressById = async (addressId: string, token: string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `/marketPlace/GetAddressById?addressId=${addressId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || {};
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  deleteAddressById = async (addressId: string, token: string) => {
    try {
      const result = await MarketPlaceAPI.delete(
        `/marketPlace/DeleteAddress?addressId=${addressId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || {};
      } else return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  getCart = async (type: string, payload: string, token: string) => {
    try {
      let url = "";
      if (type === "city") {
        url = `cityName=${payload}`;
      } else {
        url = `addressId=${payload}`;
      }
      const result = await MarketPlaceAPI.get(`/marketPlace/GetMyCart?${url}`, {
        headers: {
          Authorization: token,
        },
      });
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getOrderDetailForPayment = async (token: string, addressId: number,callbackBaseUrl:string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `MarketPlace/GetOrderDetailForPayment?addressId=${addressId}&callbackBaseUrl=${callbackBaseUrl}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getOrderDetailAfterPayment = async (token: string, orderId: string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `MarketPlace/GetOrderDetail?orderId=${orderId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getOrderDetailForReGeneratePayment = async (token: string, orderId: string,callbackBaseUrl:string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `MarketPlace/GetOrderDetailForReGeneratePayment?orderId=${orderId}&callbackBaseUrl=${callbackBaseUrl}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getOrderList = async (token: string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `MarketPlace/GetOrderList`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  getTopCategories = async () => {
    try {
      const result = await MarketPlaceAPI.get(
        `/MarketPlace/GetTopCategories?cityName=Delhi&categoryCount=11`
      )
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  getMarketPlaceWidgetData = async (categorySlug:string) => {
    try {
      const result = await MarketPlaceAPI.get(
        `/MarketPlace/GetCategoryProductsForWebProduct?CityName=Delhi&SearchSlug=${categorySlug}&ProductCount=11`
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  getPayUOfferList = async () => {
    try {
      const result = await MarketPlaceAPI.get(
        `/MarketPlace/GetPayUBankOffers`
      );
      if (
        result &&
        result.status == 200 &&
        result.data.status == 1 &&
        result.data.data
      ) {
        const value = result.data.data;
        return value || [];
      } else return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
export default new MarketPlaceService();
