import axios from "axios";
import {CountryCode, ImageResizeType} from "../constants";
export function getKeyByValue(EnumObject: any, value: string) {
    const indexOfS = Object.values(EnumObject).indexOf(value as unknown as typeof EnumObject);  
    const key = Object.keys(EnumObject)[indexOfS];  
    return key;
}
export function formatDate(date: string) {
  const _date = new Date(date);
  const options:any = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = _date.toLocaleDateString('en-US', options);
  return formattedDate;
}
export function pushRoute  (Route:string | any) {
  if(typeof window != undefined){
   window.location.href = Route
  }
}
export function imageResize(imagePath:string, imageType:ImageResizeType=ImageResizeType.Normal)
{
  if(imageType==ImageResizeType.Blur)
  {
    const blurImage=process.env.BLUR_IMAGE_RESIZE_URL || "";
    return imagePath && blurImage + imagePath;
  }
  else
  {
    const imageURL=process.env.IMAGE_RESIZE_URL || "";
    return imagePath && imageURL + imagePath;
  }
}

export function formatToIndianCurrency(amount: number|string): string {
  return amount.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'decimal'
  });
}


export function debounce(fn: Function, ms = 300){
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export function getCurrencyFormat(amount: number, countryCode: string): string {
  if (amount == null) {
    return "";
  }

  const no = amount.toFixed(2).split(".");
  const integerPart = no[0];
  const decimalPart = no[1];

  if (countryCode === CountryCode.INR) {
    // Format for INR (Indian Rupee)
    return integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") + (decimalPart ? "." + decimalPart : "");
  } else {
    // Format for other country codes (default: US/European style)
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (decimalPart ? "." + decimalPart : "");
  }
}

export function sendMail(body: string, fileData: File | null) {
  const isSendMailEnabled = Number(process.env.IS_ERROR_MAIL_ENABLED || 0);
  const mailsIds = process.env.MARKETPLACE_API_ERROR_SEND_MAILS?.split(',');

  if (isSendMailEnabled === 1 && mailsIds && mailsIds.length > 0) {
    const formData = new FormData();

    formData.append('subject', 'Inco marketplace api error mail');
    formData.append('mailBody', body);

    mailsIds.forEach(email => {
      formData.append('emailIds[]', email);
    });

    if (fileData) {
      formData.append('attachment', fileData, fileData.name);
    }

    axios.post(
      `${process.env.INCO_CONTENT_API_BASE_URL}v1/send-mail/commonSendMail`,
      formData
    ).catch((err) => {
      console.error("Failed to send error email:", err);
    });
  }
}

export function getYear() {
  const date = new Date();  
  const year = date.getFullYear();
  return year;
}