import axios, {AxiosError, AxiosInstance, AxiosResponse, AxiosRequestConfig, InternalAxiosRequestConfig   } from 'axios';
import { setCookie } from './Cookie';
import { sendMail } from './common';

abstract class HttpClient{
    public readonly instance: AxiosInstance;

    public constructor(baseURL: string) {
      this.instance = axios.create({
        baseURL,
      });

      this._initializeResponseInterceptor();
    }

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
          this._handleResponse,
          //this._handleError
        );
    };
    
    private _handleResponse = ({ data, status, statusText, headers, config }: AxiosResponse) =>({data, status, statusText, headers, config});
    
    protected _handleError = (error: AxiosError) => {
      if(error.config)      
      console.error(`API URL -  ${error.config.baseURL}${error.config.url}`); 
      console.error(`AxiosError - ${error.code} : ${error.message}`); 
      return error;
    }
}

class BeatsClient extends HttpClient{
    private static api?: AxiosInstance;
    private constructor() {
        const baseURL=process.env.BEATS_API_BASE_URL||"";
        super(baseURL)
    }

    public static getInstance() {
        if (!this.api) {
          this.api = new BeatsClient().instance;
        }    
        return this.api;
    }

    // private _initializeRequestInterceptor = () => {
    //     this.instance.interceptors.request.use(
    //       this._handleRequest,
    //       this._handleError,
    //     );
    // };

    // private _handleRequest = (config: AxiosRequestConfig) => {    
    //     config.headers={

    //     }
    //     return config;
    // }
}

class BeatsLeadClient extends HttpClient{
  private static api?: AxiosInstance;
  private constructor() {
      const baseURL=process.env.BEATS_LEAD_BASE_URL||"";
      super(baseURL)
  }

  public static getInstance() {
      if (!this.api) {
        this.api = new BeatsLeadClient().instance;
      }    
      return this.api;
  }
}
//BEATS NEW CORE API's Client
class IncoCoreLeadApiClient extends HttpClient{
  private static api?: AxiosInstance;
  private constructor() {
      const baseURL=process.env.INCO_API_LEAD_BASE_URL||"";
      super(baseURL)
  }

  public static getInstance() {
      if (!this.api) {
        this.api = new IncoCoreLeadApiClient().instance;
      }    
      return this.api;
  }
}
class IncoCoreApiClient extends HttpClient{
  private static api?: AxiosInstance;
  private constructor() {
      const baseURL=process.env.INCO_API_BASE_URL||"";
      super(baseURL)
  }

  public static getInstance() {
      if (!this.api) {
        this.api = new IncoCoreApiClient().instance;
      }    
      return this.api;
  }
}
class MarketPlaceApiClient extends HttpClient {
  private static api?: AxiosInstance;
  private constructor() {
    const baseURL = process.env.INCO_API_BASE_URL || "";
    super(baseURL)
  }

  public static getInstance() {
    if (!this.api) {
      this.api = new MarketPlaceApiClient().instance;
      this.api.interceptors.response.use((res: AxiosResponse): any => {
        const token = res?.config?.headers?.Authorization || "";
        if (res.status != 200 || [0].includes(res.data.status)) {
          const body = `Api Response = ${JSON.stringify(res.data)}\n ReqMethod = ${res.config.method}\n Error = ${res.status} - ${res.statusText}\n Env = ${process.env.ENVIRONMENT}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n token = ${token}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${res.config.data || ""}\n`;
          sendMail(body, null);
        } else if (res.data.status == 3) {
          console.log(`Api Response = ${JSON.stringify(res.data)}\n ReqMethod = ${res.config.method}\n Error = ${res.status} - ${res.statusText}\n Env = ${process.env.ENVIRONMENT}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n token = ${token}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${res.config.data || ""}\n`);
        }
        return res;
      }, (err) => {
        const res = err.response
        const token = res?.config?.headers?.Authorization || "";
        const body = `Api Response = ${JSON.stringify(res.data)}\n ReqMethod = ${res.config.method}\n Error = ${res.status} - ${res.statusText}\n AxiosError - ${err.code} : ${err.message}\n Env = ${process.env.ENVIRONMENT}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n token = ${token}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${res.config.data || ""}\n`;
        sendMail(body, null)
        return err;
      });
    }
    return this.api;
  }
}
//BEATS NEW CORE API's Client END
//INCO Content API Client
class IncoClient extends HttpClient{
    private static api?: AxiosInstance;
    private constructor() {
        const baseURL=process.env.INCO_CONTENT_API_BASE_URL||"";
        super(baseURL)
    }

    public static getInstance() {
        if (!this.api) {
          this.api = new IncoClient().instance;
        }    
        return this.api;
    }
}
class IncoLibraryClient extends HttpClient{
  private static api?: AxiosInstance;
  private constructor() {
      const baseURL=process.env.INCO_LIBRARY_API_BASE_URL||"";
      super(baseURL)
  }

  public static getInstance() {
      if (!this.api) {
        this.api = new IncoLibraryClient().instance;
      }    
      return this.api;
  }
}


class IncoLibraryAeClient extends HttpClient{
  private static api?: AxiosInstance;
  private constructor() {
      const baseURL=process.env.INCO_LIBRARY_AE_API_BASE_URL||"";
      super(baseURL)
  }

  public static getInstance() {
      if (!this.api) {
        this.api = new IncoLibraryAeClient().instance;
      }    
      return this.api;
  }
}
//INCO Content API Client END
class CmsClient extends HttpClient{
  private static api?: AxiosInstance;
  private constructor() {
      const baseURL=process.env.CMS_API_BASE_URL||"";
      super(baseURL)
  }

  public static getInstance() {
      if (!this.api) {
        this.api = new CmsClient().instance;
      }    
      return this.api;
  }
}

class ApiClient extends HttpClient{
  private static api?: AxiosInstance;
  private constructor() {
      const baseURL=process.env.SITE_URL||"";
      super(baseURL)
  }

  public static getInstance() {
      if (!this.api) {
        this.api = new ApiClient().instance;
        this.api.interceptors.request.use((config:AxiosRequestConfig):InternalAxiosRequestConfig =>{
          config.withCredentials=true;
          let _date = new Date();
          _date.setMinutes(_date.getMinutes() + 10);
          const token=_date.getTime().toString();
          setCookie("leadtoken",token);
          config.headers={
            ...config.headers,
            "token":token,
          }
          return config as InternalAxiosRequestConfig;
        })
      }    
      return this.api;
  }
}


const LeadAPI=BeatsLeadClient.getInstance();
const BeatsAPI=BeatsClient.getInstance();
const IncoAPI=IncoClient.getInstance();
const CmsAPI=CmsClient.getInstance();
const API=ApiClient.getInstance();
const IncoCoreAPI=IncoCoreApiClient.getInstance();
const IncoCoreLeadAPI=IncoCoreLeadApiClient.getInstance();
const IncoLibraryAPI=IncoLibraryClient.getInstance();
const IncoLibraryAeAPI=IncoLibraryAeClient.getInstance();
const MarketPlaceAPI=MarketPlaceApiClient.getInstance();

export {
    LeadAPI,
    BeatsAPI,
    CmsAPI,
    IncoAPI,
    API,
    IncoCoreAPI,
    IncoCoreLeadAPI,
    IncoLibraryAPI,
    MarketPlaceAPI,
    IncoLibraryAeAPI    
}